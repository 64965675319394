import DiamondGem from './diamondGem.svg';
import TitaniumGem from './titaniumgemstone.svg';
import PlatinumGem from './platinumgemstone.svg';
import GoldGem from './goldgemstone.svg';
import SilverGem from './silvergemstone.svg';
import BronzeGem from './bronzegemstone.svg';
import Health from './healthBackground.svg';
import Life from './lifeBackground.svg';
import Recruitment from './recruitmentBackgrount.svg';
import NoTransactionImage from './no-transactions.svg';
import RealtimeData from './realtime-data.svg';
import RealtimeCall from './realtime-call.svg';
import topBanner from 'src/assets/home/images/hero.png';
import multipleIcon from 'src/assets/home/images/multiple-icon.png';
import realtimeIcon from 'src/assets/home/images/realtime-icon.png';
import accessIcon from 'src/assets/home/images/access-icon.png';
import leads from 'src/assets/home/images/leads.jpg';
import mapIcon from 'src/assets/home/images/map-icon.png';
import managementIcon from 'src/assets/home/images/management-icon.png';
import mobileIcon from 'src/assets/home/images/mobile-icon.png';
import laptop from 'src/assets/home/images/laptop.jpg';
import compliant from 'src/assets/home/images/complient.jpg';
import logo from 'src/assets/home/images/logo.png';
import footerLogo from 'src/assets/home/images/logo-footer.png';
import tomTestimonial from 'src/assets/home/images/testimonials/tom-goodwin.jpg';
import dannyTestimonial from 'src/assets/home/images/testimonials/danjy-wells.jpg';
import jamesTestimonial from 'src/assets/home/images/testimonials/james-hensley.png';
import videoThumbnail from 'src/assets/home/images/video-thumbnail.avif';
import calendarAlert from 'src/assets/Images/calendar-alert.svg';
import clients from 'src/assets/Images/clients.svg';
import IntegrityCircleIcon from 'src/assets/Images/integrity-circle-icon.svg';
import PhoneBurnerIcon from 'src/assets/Images/phone-burner-icon.svg';
import PiggyBank2 from 'src/assets/Images/piggy-bank-2.svg';
import filterIcon from 'src/assets/Images/filter-icon.svg';
import sortIcon from 'src/assets/Images/sort-icon.svg';
import noResults from 'src/assets/Images/no-results.svg';
import ChevronActiveFirst from 'src/assets/Images/ChevronFirstActive.svg';
import ChevronActiveLast from 'src/assets/Images/ChevronLastActive.svg';
import ChevronInactiveFirst from 'src/assets/Images/ChevronInactiveFirst.svg';
import ChevronInactiveLast from 'src/assets/Images/ChevronInactiveLast.svg';
import lifeIcon from 'src/assets/Images/life-icon.svg';
import healthIcon from 'src/assets/Images/health-icon.svg';
import recruitmentIcon from 'src/assets/Images/recruitment-icon.svg';
import openLink from 'src/assets/Images/open-link.svg';
import noResultsImage from 'src/assets/Images/NoResultsImage.svg';
import checkIcon from 'src/assets/Images/phoneburner/check-icon.svg';
import integrityPhoneburnerIcons from 'src/assets/Images/phoneburner/integrity-phoneburner-icons.svg';
import phoneburnerOutline from 'src/assets/Images/phoneburner/phoneburner-outline.svg';
import phoneburnerMarketingBg from 'src/assets/Images/phoneburner/phoneburner-bg.png';
import phoneBurnerRow1 from 'src/assets/Images/phoneburner/phoneburner-row-1.svg';
import phoneBurnerRow2 from 'src/assets/Images/phoneburner/phoneburner-row-2.svg';
import phoneBurnerRow3 from 'src/assets/Images/phoneburner/phoneburner-row-3.svg';
import phoneBurnerRow4 from 'src/assets/Images/phoneburner/phoneburner-row-4.svg';
import phoneBurnerRow5 from 'src/assets/Images/phoneburner/phoneburner-row-5.svg';
import assignLeadIcon from 'src/assets/Images/assignlead.svg';
import agentReferral from 'src/assets/Images/crm-agent-referral.svg';
import phone from 'src/assets/Images/phone.svg';

const appImages = {
  DiamondGem,
  TitaniumGem,
  PlatinumGem,
  GoldGem,
  SilverGem,
  BronzeGem,
  Health,
  Life,
  Recruitment,
  NoTransactionImage,
  RealtimeData,
  RealtimeCall,
  topBanner,
  multipleIcon,
  realtimeIcon,
  accessIcon,
  leads,
  mapIcon,
  managementIcon,
  mobileIcon,
  laptop,
  compliant,
  logo,
  footerLogo,
  tomTestimonial,
  dannyTestimonial,
  jamesTestimonial,
  videoThumbnail,
  calendarAlert,
  clients,
  IntegrityCircleIcon,
  PhoneBurnerIcon,
  PiggyBank2,
  filterIcon,
  sortIcon,
  noResults,
  ChevronActiveFirst,
  ChevronActiveLast,
  ChevronInactiveFirst,
  ChevronInactiveLast,
  lifeIcon,
  healthIcon,
  openLink,
  noResultsImage,
  recruitmentIcon,
  checkIcon,
  integrityPhoneburnerIcons,
  phoneburnerMarketingBg,
  phoneBurnerRow1,
  phoneBurnerRow2,
  phoneBurnerRow3,
  phoneBurnerRow4,
  phoneBurnerRow5,
  phoneburnerOutline,
  assignLeadIcon,
  agentReferral,
  phone,
};

export default appImages;
