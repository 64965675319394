import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import './ContactSupport.scss';
import { setIsContactSupportModal } from 'src/app/redux/actions/actions';
import { supportEmail, supportPhoneNumber } from 'src/app/utils/Constants';
import { getFormattedPhoneNumber } from 'src/app/utils/Utils';
import appImages from 'src/assets/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from 'src/assets/icons-new';

const ContactSupport = () => {
  const isContactSupportModal = useSelector(
    (state) => state?.loginReducer?.isContactSupportModal,
  );
  const dispatch = useDispatch();
  const hideModal = () => {
    dispatch(setIsContactSupportModal(false));
  };

  const openLiveChat = () => {
    if (window.fcWidget && typeof window.fcWidget.open === 'function') {
      window.fcWidget.open();
      dispatch(setIsContactSupportModal(false));
    }
  };

  return (
    <Modal
      className="modal-contact-support"
      show={isContactSupportModal}
      onHide={hideModal}
    >
      <Modal.Header closeButton>Contact Support</Modal.Header>
      <Modal.Body className="modal-body">
        <div>
          Call or email one of our support representatives to help resolve your
          issue.
        </div>
        <div className="body-content-container">
          <div data-testid="contact-support-phone" className="support-item">
            <div className="support-item-icon-container">
              <img src={appImages.phone} alt="phone icon" />
            </div>
            <div className="fs-16 fw-600 integrity-navy-font">
              <a
                href={`tel:${supportPhoneNumber}`}
                className="integrity-navy-font"
              >
                {getFormattedPhoneNumber(supportPhoneNumber)}
              </a>
            </div>
          </div>
          <div data-testid="contact-support-email" className="support-item">
            <div className="support-item-icon-container">
              <FontAwesomeIcon
                icon={icons.emailLight}
                className="color-primary-main fs-16"
              />
            </div>
            <div className="fs-16 fw-600 integrity-navy-font">
              <a
                href={`mailto:${supportEmail}`}
                className="integrity-navy-font"
              >
                {supportEmail}
              </a>
            </div>
          </div>
          <div data-testid="contact-support-live-chat" className="support-item">
            <div className="support-item-icon-container">
              <FontAwesomeIcon
                icon={icons.liveChatLight}
                className="color-primary-main fs-16"
              />
            </div>
            <button
              className="fs-16 fw-600 integrity-navy-font"
              onClick={openLiveChat}
            >
              Live Chat
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactSupport;
